import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import { Article } from "../../components"

const FAQsPage: React.FC = () => {
  return (
    <Container>
      <Article
        title="Frequently Asked Questions"
        image={
          <StaticImage
            src="../../assets/images/FAQs/FAQs-1.png"
            alt=""
            placeholder="blurred"
            quality={100}
          />
        }
      >
        <p>Everything you need to know about Workerslife.</p>
      </Article>
    </Container>
  )
}

export default FAQsPage

const Container = styled.div`
  padding: 0 20px;

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 58px;
  }
`
