import React from "react"
import styled from "styled-components"

import CollapsibleList from "../../components/CollapsibleList"
import FAQText from "../../components/FAQ"
import { faqData } from "../../constants/faq"
import { FAQJsonLd } from "gatsby-plugin-next-seo"

const FAQsContent: React.FC = () => {
  return (
    <Container>
      <FAQJsonLd
        questions={faqData.content.flatMap(MainFAQ => {
          return MainFAQ.data.map(FAQ => {
            return {
              question: FAQ.title,
              answer: FAQ.text.map(text => text.description).join(""),
            }
          })
        })}
      />
      {faqData.content.map(MainFAQ => {
        return (
          <div key={MainFAQ.header} className="faq-container">
            <h2>{MainFAQ.header}</h2>
            {MainFAQ.data.map(FAQ => (
              <CollapsibleList title={FAQ.title} key={FAQ.id} id={FAQ.id}>
                {FAQ.text.map(text => (
                  <FAQText text={text} key={text.id} />
                ))}
              </CollapsibleList>
            ))}
          </div>
        )
      })}
      <h2>Conclusion</h2>
      <p>
        We hope these FAQs have addressed your questions about
        Workerslife&apos;s insurance products. If you have any further queries
        or need more information, please don&apos;t hesitate to contact our
        customer service at 0861 520 520 or visit our website
        www.workerslife.co.za. We are here to help you secure your financial
        future and provide the best insurance benefits for you and your loved
        ones. Choose Workerslife for your insurance needs and experience
        financial security like never before. Don&apos;t wait; take control of
        your financial future today!
      </p>
    </Container>
  )
}

export default FAQsContent

const Container = styled.div`
  padding: 0 20px;

  .faq-container {
    margin-top: 50px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 96px 0 58px;

    .descriptionNumber {
      padding-right: 90px;
    }
  }
`
