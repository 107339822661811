import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import { FAQsContent, FAQsPage } from "../sections/FAQs"

const FAQs: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Workerslife FAQs: Answers to Common Questions"
        noSuffix
        description="Explore our FAQs to learn about life & funeral insurance, short-term coverage, and more. Secure your family's future with Workerslife. Visit us today!"
      />
      <FAQsPage />
      <FAQsContent />
    </PageWrapper>
  )
}

export default FAQs

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  @media only screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    row-gap: 85px;
  }

  @media only screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding-bottom: 0;
  }
`
